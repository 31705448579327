@import "~ngx-toastr/toastr";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --themecolor: #fa2c54;
  --secondarycolor: #001934;
  --inputBoxColor: rgba(7, 71, 168, 0.5);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style-type: none;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

html,
body {
  height: 100%;
  width: 100%;
}

a {
  cursor: pointer;
}

//Custom Mat Select CSS
.mat-form-field-wrapper {
  padding-bottom: 0px;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0em !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: transparent !important;
}

.mat-select {
  font-family: "poppins", sans-serif !important;
}

.mat-form-field-underline {
  display: none !important;
}

.mat-form-field-infix {
  padding: 0px 0px;
  border-top: 0px;
  width: 130px !important;
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.3rem;
}

.mat-select-placeholder {
  color: #6470bb;
  font-size: 0.9rem;
  font-weight: 500;
}

.mat-select-value {
  color: #6470bb;
  font-size: 0.9rem;
  font-weight: 500;
}

.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
  transform: none !important;
}

.mat-select-value-text {
  font-size: 13px !important;
}

.mat-select-placeholder {
  font-size: 13px;
}

.mat-option-text {
  overflow: visible !important;
}

.mat-option {
  overflow: visible !important;
}

